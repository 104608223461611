import { Button, CircularProgress, Paper, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { readString } from "react-papaparse";
import { Link, useParams } from "react-router-dom";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import "./users.css";

enum LoadingState {
  NO_DATA = "no_data",
  LOADING = "loading",
  ERROR = "error",
  LOADED = "loaded",
}
export const UsersPage: React.FC<{}> = () => {
  const [startDate, setStartDate] = React.useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = React.useState(LoadingState.NO_DATA);
  const [dataLocation, setDataLocation] = React.useState(undefined as string | undefined);
  const { privateId } = useParams<{ privateId: string }>();
  const [data, setData] = React.useState(undefined as undefined | any);
  const [columns, setColumns] = React.useState([] as { Header: string; accessor: string }[]);

  const onStartChange = React.useCallback(
    (e) => {
      setStartDate(e.target.value);
    },
    [setStartDate]
  );

  const onEndChange = React.useCallback(
    (e) => {
      setEndDate(e.target.value);
    },
    [setEndDate]
  );

  const onSubmit = React.useCallback(() => {
    const start = startDate;
    const end = endDate;
    if (!start || !end) {
      return;
    }
    setLoading(LoadingState.LOADING);
    setData(undefined);
    setDataLocation(undefined);
    const date1 = moment(start).unix();
    const date2 = moment(end).add(1, 'day').unix();
    fetch("https://rakc2ol6yj.execute-api.us-east-2.amazonaws.com/CelerityQuery", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify({ start: date1, end: date2, clientId: privateId }),
      headers: { "Content-Type": "application/json" },
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        setLoading(LoadingState.LOADED);
        setDataLocation(r.location);
      })
      .catch((err) => {
        setLoading(LoadingState.ERROR);
        console.log(err);
      });
  }, [startDate, endDate, privateId]);

  React.useEffect(() => {
    if (!data && loading === LoadingState.LOADED && dataLocation) {
      fetch(dataLocation)
        .then((r) => r.text())
        .then((r) => {
          const parsed = readString(r, { header: true, skipEmptyLines: true });
          setData(parsed.data);
          setColumns(makeColumns(parsed.meta.fields || []));
        });
    }
  }, [data, loading, dataLocation]);

  const makeColumns = (rawColumns: string[]) => {
    return rawColumns.map((column) => {
      return { Header: column, accessor: column, width: column === 'ID' ? 100 : undefined };
    });
  };

  return (
    <>
      <Paper className="userDisplay">
        <form>
          <div className={"dateRow"}>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              className={"startDate"}
              value={startDate}
              onChange={onStartChange}
            />
            <TextField
              id="date"
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              className={"endDate"}
              value={endDate}
              onChange={onEndChange}
            />
          </div>
          <div className="buttonRow">
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              disableElevation
              className="fetchButton"
              onClick={onSubmit}
            >
              <span>Fetch Data</span>
            </Button>
          </div>
        </form>
      </Paper>
      {
        <div className="tableWrapper">
          {(loading === LoadingState.LOADED || loading === LoadingState.LOADING) && (
            <div className="linkRow">
              <Typography>{"Generated Data:"}</Typography>
              {loading === LoadingState.LOADING ? (
                <CircularProgress size={16} style={{ marginLeft: "5px" }} />
              ) : (
                <Typography>
                  <a href={dataLocation} style={{ marginLeft: "5px" }}>
                    Download
                  </a>
                </Typography>
              )}
            </div>
          )}
          {data && <ReactTable data={data} columns={columns} defaultPageSize={10} className="-striped -highlight" />}
        </div>
      }
    </>
  );
};
