import studio6 from './logos/studio6.png';
import celerity from './logo.png';

const imageMap: {[key: string]: {img: string, text: string}} = {
  '1481230085': {img: studio6, text: 'Studio 6',}
}

const defaultImg = {
  img: celerity,
  text: 'Celerity Networks'
}

export const getLogo = (id: string) => {
  const img = imageMap[id];
  if (img) {
    return img;
  } else {
    return defaultImg;
  }
}