import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import "./index.css";
import logo from "./logo.png";
import { getLogo } from "./logo_map";

enum State {
  Unauthenticated = "Unauthenticated",
  Authenticating = "Authenticating...",
  Authenticated = "Authenticated",
  Error = "Error!",
  CheckAuth = "CheckAuth",
  AlreadyAuth = "AlreadyAuth",
}

function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function vaildatePhone(phone: string) {
  const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  return re.test(phone);
}

function checkStatus(res: any) {
  //console.log(res.status);
  if (res.ok) {
    // res.status >= 200 && res.status < 300
    return res;
  } else {
    throw Error(res.statusText);
  }
}

export const AuthenticatePage: React.FC<{}> = () => {
  const [authState, setAuthState] = React.useState(State.CheckAuth);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const { publicId, macId } = useParams<{ publicId: string; macId: string }>();

  React.useEffect(() => {
    if (authState === State.CheckAuth) {
      fetch("https://rakc2ol6yj.execute-api.us-east-2.amazonaws.com/CheckAuth", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify({ id: macId, clientId: publicId }),
        headers: { "Content-Type": "application/json" },
      })
        .then((r) => checkStatus(r))
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          // setTimeout(() => {
          //   setAuthState(State.Authenticated);
          // }, 4000);
          if (r.authorized) {
            setAuthState(State.AlreadyAuth);
          } else {
            setAuthState(State.Unauthenticated);
          }
        })
        .catch((err) => {
          setAuthState(State.Unauthenticated);
          console.log(err);
        });
    }
  }, [authState, setAuthState, macId, publicId]);

  const handleNameChange = React.useCallback(
    (e) => {
      setName(e.target.value);
    },
    [setName]
  );

  const handleEmailChange = React.useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const handlePhoneChange = React.useCallback(
    (e) => {
      setPhone(e.target.value);
    },
    [setPhone]
  );

  const handleCheckboxChange = React.useCallback(
    (e) => {
      setChecked(e.target.checked);
    },
    [setChecked]
  );

  const emailValid = validateEmail(email.trim());
  const emailErrorMsg = email.trim() && !validateEmail(email.trim()) ? "Enter a valid email" : "";

  const phoneValid = vaildatePhone(phone.trim());
  const phoneErrorMsg = phone.trim() && !vaildatePhone(phone.trim()) ? "Enter a valid phone number" : "";

  const enabled =
    name.trim() && email.trim() && checked && emailValid && phoneValid && authState !== State.Authenticating;

  const authAgain = React.useCallback(() => {
    setAuthState(State.Unauthenticated);
  }, [setAuthState]);

  const handleAuthentication = React.useCallback(() => {
    setAuthState(State.Authenticating);
    fetch("https://rakc2ol6yj.execute-api.us-east-2.amazonaws.com/CelerityAPI", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify({ id: macId, email: email, name: name, clientId: publicId, phone: phone }),
      headers: { "Content-Type": "application/json" },
    })
      .then((r) => checkStatus(r))
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        // setTimeout(() => {
        //   setAuthState(State.Authenticated);
        // }, 4000);
        setAuthState(State.Authenticated);
        console.log("success");
        window.location.href = r.redirectURI;
      })
      .catch((err) => {
        setAuthState(State.Error);
        console.log(err);
      });
  }, [setAuthState, email, name, phone, publicId, macId]);

  if (authState === State.Authenticated) {
    return (
      <Paper className="inputForm">
        <Typography>You are connected! You can close this page</Typography>
      </Paper>
    );
  }

  if (authState === State.CheckAuth) {
    return (
      <Paper className="inputForm">
        <Typography>Checking if Already Authorized...</Typography>
      </Paper>
    );
  }

  if (authState === State.AlreadyAuth) {
    return (
      <Paper className="inputForm">
        <div>
          <Typography>You seem to be already authorized! You should have internet access and can close the window</Typography>
        </div>
        <div>
          <Typography>
            No internet?
            <Link onClick={authAgain} style={{ cursor: "pointer", marginLeft: "5px" }}>
              Authorize Again
            </Link>
          </Typography>
        </div>
      </Paper>
    );
  }

  if (authState === State.Error) {
    return (
      <Paper className="inputForm">
        <Typography>An error occured. Try again!</Typography>
      </Paper>
    );
  }

  return (
    <Paper className="inputForm" elevation={4}>
      <div className="logo">
        <img src={getLogo(publicId).img} alt="Logo" style={{ width: "50px", height: "50px" }} />
        <Typography variant="h6" style={{ marginLeft: "10px" }}>
          {getLogo(publicId).text}
        </Typography>
      </div>
      <form>
        <TextField
          name="name"
          label="Name"
          margin="normal"
          fullWidth
          value={name}
          onChange={handleNameChange}
          autoComplete={"off"}
        />
        <TextField
          name="email"
          label="Email"
          margin="normal"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={!!emailErrorMsg}
          helperText={emailErrorMsg}
          autoComplete={"off"}
        />
        <TextField
          name="phone"
          label="Phone"
          margin="normal"
          fullWidth
          value={phone}
          onChange={handlePhoneChange}
          error={!!phoneErrorMsg}
          helperText={phoneErrorMsg}
          autoComplete={"off"}
        />
        <FormControl margin={"normal"}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={checked} onChange={handleCheckboxChange} />}
            label={
              <Typography>
                I accept{" "}
                <Link href="/terms.html" target="_blank">
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
        </FormControl>
        <div>
          {authState === State.Authenticating && (
            <div>
              <CircularProgress size={24} />
            </div>
          )}
          <FormControl margin="normal">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleAuthentication}
              disabled={!enabled}
            >
              Connect
            </Button>
          </FormControl>
        </div>
      </form>
    </Paper>
  );
};
