import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthenticatePage } from "./authenticate";
import { UsersPage } from "./users";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:publicId/auth/:macId">
          <AuthenticatePage />
        </Route>
        <Route path="/:privateId/user/data">
          <UsersPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
